<template>
  <div id="top">
	<!-- <banner :bannerUrl="bannerUrl"></banner> -->
    <section class="hero-area">
<div class="hero-slider">
    callforpaperBg.jpg
    <div class="breadcrumbs overlay" :style="{backgroundImage: 'url(' + require('../assets/images/banner01.png') + ')' }">
<div class="container">
<div class="row align-items-center">
<div class="col-lg-8 offset-lg-2 col-md-12 col-12">
<div class="breadcrumbs-content">
<h1 class="page-title">组委会</h1>
<p>我们荣幸地宣布，2024年第二届储能与智慧电站关键技术论坛即将盛大召开！邀您共探储能发展新篇章！</p>
</div>
<ul class="breadcrumb-nav">
<li><a style="cursor:pointer" @click="goToIndex()">首页</a></li>
<li>组委会</li>
</ul>
</div>
</div>
</div>
</div>
</div>  
</section>

<section class="section blog-single">
<div class="container">
<div class="row">
<div class="col-lg-10 offset-lg-1 col-md-12 col-12">
<div class="single-inner">

<div class="post-details">
<div class="detail-inner">

<ul class="custom-flex post-meta">
<li>
<!-- <a href="javascript:void(0)">
<i class="lni lni-calendar"></i>
2024年07月24日
</a> -->
</li>
</ul>
<h2 class="post-title" style="text-align: center;">
<a href="javascript:void(0)" >会议主席 </a>
</h2>
<h3 style="font-weight: 400;margin-top: 5px;text-align: center;">孙江</h3>
<h2 class="post-title" style="text-align: center;">
<a href="javascript:void(0)" style="text-align: center;">技术方案委员会 </a>
</h2>
<h3 style="font-weight: 400;margin-top: 5px;text-align: center;">张俊红、李金伟、于洋洋</h3>
<h2 class="post-title" style="text-align: center;">
<a href="javascript:void(0)" style="text-align: center;">组织委员会 </a>
</h2>
<h3 style="font-weight: 400;margin-top: 5px;text-align: center;">任成祖、郭伟、闫飞、贺莹、刘金剑、石丽雯、靳艳梅 </h3>
<h2 class="post-title" style="text-align: center;">
<a href="javascript:void(0)" style="text-align: center;">出版主席 </a>
</h2>
<h3 style="font-weight: 400;margin-top: 5px;text-align: center;">王莹</h3>
<div class="post-tags-media">

<div class="post-social-media">


</div>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
</section>



 

<a href="javascript:void(0)" class="scroll-top btn-hover" @click="scrollToAnchor('top')">
<i class="lni lni-chevron-up"></i>
</a>
  </div>
</template>
  
<script>
 
  
  import indexnav from "@/components/nav/indexnav.vue"
  import banner from "@/components/header/banner.vue"


export default {
   name:"IndexPage",
   components:{
    indexnav,
	banner
   },
   data(){
    return{
		bannerUrl:["BANNER.png"]
    }
   },
   mounted(){
  	//  TouchSlide({ 
	// 		slideCell:"#news_slide",
	// 		mainCell:".bd ul",
	// 		effect:"leftLoop",
	// 		autoPlay:true//自动播放
	//  });
    const slide = document.querySelector(".hero-slider");
    console.log(slide)
	   tns({
            container: '.hero-slider',
            items: 1,
            slideBy: 'page',
            autoplay: false,
            mouseDrag: true,
            gutter: 0,
            nav: true,
            controls: false,
            controlsText: ['<i class="lni lni-arrow-left"></i>', '<i class="lni lni-arrow-right"></i>'],
        });
	

	 document.addEventListener('click', evnt => {
		let anchor = evnt.target.closest('a[href^="#"]')
		if (anchor) {
			evnt.preventDefault()
			let value = anchor.getAttribute('set_data');
		    if(value){
 			  this.newsDetail(value)
			}
           
        }
        })
 },
 methods: {
    downLoadFile(fileName){
        const fileUrl =  '/img/'+fileName;
        const link = document.createElement('a');
        link.href = fileUrl;
        link.setAttribute('download',fileName);
        link.click();
    },
       goToIndex(){
       this.$router.push({
          path:"/index"
        })
    },
    scrollToAnchor(id){
        var anchor = document.getElementById(id);
        anchor.scrollIntoView();
    },
	 newsDetail(index){
		console.log(11111)
        this.$router.push({
          path:"/newsDetail/"+index
        })
     },
	 caseDetail(type){
     this.$router.push({
          path:"/caseDetail/"+type
      })
    }
 }
    
}
</script>
  
<style>

    
    

</style>