<template>
  <div id="top">
	<!-- <banner :bannerUrl="bannerUrl"></banner> -->
    <section class="hero-area">
<div class="hero-slider">
    callforpaperBg.jpg
    <div class="breadcrumbs overlay" :style="{backgroundImage: 'url(' + require('../assets/images/banner01.png') + ')' }">
<div class="container">
<div class="row align-items-center">
<div class="col-lg-8 offset-lg-2 col-md-12 col-12">
<div class="breadcrumbs-content">
<h1 class="page-title">会议征稿</h1>
<p>我们荣幸地宣布，2024年第二届储能与智慧电站关键技术论坛即将盛大召开！邀您共探储能发展新篇章！</p>
</div>
<ul class="breadcrumb-nav">
<li><a style="cursor:pointer" @click="goToIndex()">首页</a></li>
<li>会议征稿</li>
</ul>
</div>
</div>
</div>
</div>
</div>  
</section>

<section class="section blog-single">
<div class="container">
<div class="row">
<div class="col-lg-10 offset-lg-1 col-md-12 col-12">
<div class="single-inner">

<div class="post-details">
<div class="detail-inner">

<ul class="custom-flex post-meta">
<li>
<a href="javascript:void(0)">
<i class="lni lni-calendar"></i>
2024年07月24日
</a>
</li>
</ul>
<h2 class="post-title">
<a href="javascript:void(0)">【 第二届储能与智慧电站关键技术论坛】会议征稿启事 </a>
</h2>
<p>各位储能与智慧电站领域的专家学者、工程师、科研工作者们，注意啦！

<br>我们荣幸地宣布，2024年第二届储能与智慧电站关键技术论坛即将盛大召开！邀您共探储能发展新篇章！

<br>会议时间：2024年10月18日至20日
<br>预投稿报名截止时间：2024年7月30日
<br>全文截稿时间：2024年9月10日

<br>本次论坛由天津大学储能中心联合天津仁爱学院主办，旨在推动储能技术与智慧电站的创新与发展，为实现国家的“3060双碳目标”贡献力量。诚邀各界共同探讨“储能装备、水电装备、智慧电站、数字孪生、知识工程、智能设计、智能运维、增效扩容”的关键技术。</p>
<h3>征稿主题涵盖：</h3>
<ul class="list">
<li><i class="lni lni-chevron-right-circle"></i>多场景下储能的地位</li>
<li><i class="lni lni-chevron-right-circle"></i>智慧电站及绿色电站关键技术</li>

<li>征稿内容包括但不限于：</li>

<li><i class="lni lni-chevron-right-circle"></i> 储能领域综述</li>
<li><i class="lni lni-chevron-right-circle"></i>专题论文</li>
<li><i class="lni lni-chevron-right-circle"></i>储能技术与实例分析</li>
<li><i class="lni lni-chevron-right-circle"></i>工业设计论坛与资讯</li>
</ul>
<p>2-3位专家审稿，确保稿件质量，录用论文将在《机械设计》增刊发表，版面费每页1000元。</p>

<h3>【注册与优惠】</h3>
<p>会务注册费1500元/人
    <br>投稿人员可抵扣会务费，具体凭证请联系会务组
</p>
<h3>【联系方式】</h3>
<p>投稿邮箱：icestj@126.com
</p>
<h3>【论坛亮点】</h3>
<p><i class="lni lni-chevron-right-circle"></i> 行业精英汇聚，共促储能技术创新
    <br><i class="lni lni-chevron-right-circle"></i> 加强产学研合作，推进智慧电站建设
</p>
<p>我们诚挚邀请您参与此次论坛，共同探讨储能与智慧电站的未来，期待您的精彩论文和宝贵意见！<br> 会议官网即将公布，敬请期待！ </p>

<div class="post-tags-media">
<div class="post-tags popular-tag-widget mb-xl-40">
    <p class="share-title"><a  style="color: red;border-bottom: 1px solid;cursor: pointer;" @click="downLoadFile('2024第二届储能与智慧电站关键技术论坛-预通知及征稿函.pdf')">附件： 2024第二届储能与智慧电站关键技术论坛-预通知及征稿函</a></p>

</div>
<div class="post-social-media">


</div>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
</section>



 

<a href="javascript:void(0)" class="scroll-top btn-hover" @click="scrollToAnchor('top')">
<i class="lni lni-chevron-up"></i>
</a>
  </div>
</template>
  
<script>
 
  
  import indexnav from "@/components/nav/indexnav.vue"
  import banner from "@/components/header/banner.vue"


export default {
   name:"IndexPage",
   components:{
    indexnav,
	banner
   },
   data(){
    return{
		bannerUrl:["BANNER.png"]
    }
   },
   mounted(){
  	//  TouchSlide({ 
	// 		slideCell:"#news_slide",
	// 		mainCell:".bd ul",
	// 		effect:"leftLoop",
	// 		autoPlay:true//自动播放
	//  });
    const slide = document.querySelector(".hero-slider");
    console.log(slide)
	   tns({
            container: '.hero-slider',
            items: 1,
            slideBy: 'page',
            autoplay: false,
            mouseDrag: true,
            gutter: 0,
            nav: true,
            controls: false,
            controlsText: ['<i class="lni lni-arrow-left"></i>', '<i class="lni lni-arrow-right"></i>'],
        });
	

	 document.addEventListener('click', evnt => {
		let anchor = evnt.target.closest('a[href^="#"]')
		if (anchor) {
			evnt.preventDefault()
			let value = anchor.getAttribute('set_data');
		    if(value){
 			  this.newsDetail(value)
			}
           
        }
        })
 },
 methods: {
    downLoadFile(fileName){
        const fileUrl =  '/img/'+fileName;
        const link = document.createElement('a');
        link.href = fileUrl;
        link.setAttribute('download',fileName);
        link.click();
    },
       goToIndex(){
       this.$router.push({
          path:"/index"
        })
    },
    scrollToAnchor(id){
        var anchor = document.getElementById(id);
        anchor.scrollIntoView();
    },
	 newsDetail(index){
		console.log(11111)
        this.$router.push({
          path:"/newsDetail/"+index
        })
     },
	 caseDetail(type){
     this.$router.push({
          path:"/caseDetail/"+type
      })
    }
 }
    
}
</script>
  
<style>

    
    

</style>