<template>
  <div id="top">
    <!-- <banner :bannerUrl="bannerUrl"></banner> -->
    <section class="hero-area">
      <div class="hero-slider">
        <!-- <div class="hero-inner overlay" style="background-image: url(../assets/images/banner01.png);"> -->
        <div
          class="breadcrumbs overlay"
          :style="{
            backgroundImage:
              'url(' + require('../assets/images/callforpaperBg.jpg') + ')',
          }"
        >
          <div class="container">
            <div class="row align-items-center">
              <div class="col-lg-8 offset-lg-2 col-md-12 col-12">
                <div class="breadcrumbs-content">
                  <h1 class="page-title">最新动态</h1>
                  <p>欢迎关注储能和智能电站关键技术论坛最新动态</p>
                </div>
                <ul class="breadcrumb-nav">
                  <li><a  @click="goToIndex()">首页</a></li>
                  <li>最新动态</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="section latest-news-area blog-grid-page">
      <div class="container">
        <div class="row">
          <div class="col-lg-8 col-md-7 col-12">
            <div class="row">
              <div class="col-lg-6 col-12" style="align-items: stretch" v-for="news,index in newsList" :key="index">
                <div
                  class="single-news custom-shadow-hover wow fadeInUp"
                  data-wow-delay=".2s" style="height:calc(100% - 30px);position: relative;display: flex;flex-direction: column;">
                  <div class="image">
                    <a @click="newsDetail(news.id)" >
                      <img class="thumb" style="object-fit: cover;aspect-ratio: 3 / 2;"
                        :src=news.coverImage
                        alt="#"
                    /></a>
                  </div>
                  <div class="content-body" style="flex: 1;overflow: auto;">
                    <div class="meta-data">
                      <ul>
                        <li>
                          <i class="lni lni-tag"></i>
                          <a href="javascript:void(0)">{{ news.category? news.category.name: ''}}</a>
                        </li>
                        <li>
                          <i class="lni lni-calendar"></i>
                          <a href="javascript:void(0)">{{ fmtime(news.createTime) }}</a>
                        </li>
                      </ul>
                    </div>
                    <h4 class="title">
                      <a href="javascript:void(0)"
                        >{{ news.title }}</a
                      >
                    </h4>
                    <p>{{ news.description }}</p>
                    
                  </div>
                  <div class="button" style="padding-left: 30px;padding-bottom: 30px;">
                      <a @click="newsDetail(news.id)" class="btn"
                        >阅读更多</a
                      >
                    </div>
                </div>
              </div>
              <!-- <div class="col-lg-6 col-12" style="align-items: stretch">
                <div
                  class="single-news custom-shadow-hover wow fadeInUp"
                  data-wow-delay=".4s" style="height:calc((100% - 30px)"
                >
                  <div class="image">
                    <a href="blog-single-sidebar.html"
                      ><img
                        class="thumb" style="object-fit: cover;aspect-ratio: 3 / 2;"
                        src="../assets/images/blog-grid2.jpg"
                        alt="#"
                    /></a>
                  </div>
                  <div class="content-body">
                    <div class="meta-data">
                      <ul>
                        <li>
                          <i class="lni lni-tag"></i>
                          <a href="javascript:void(0)">Language</a>
                        </li>
                        <li>
                          <i class="lni lni-calendar"></i>
                          <a href="javascript:void(0)">January 10, 2023</a>
                        </li>
                      </ul>
                    </div>
                    <h4 class="title">
                      <a href="blog-single-sidebar.html"
                        >How to Learn a Foreign Language in 3 Steps</a
                      >
                    </h4>
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's.
                    </p>
                    <div class="button">
                      <a href="blog-single-sidebar.html" class="btn"
                        >Read More</a
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-12">
                <div
                  class="single-news custom-shadow-hover wow fadeInUp"
                  data-wow-delay=".2s"
                >
                  <div class="image">
                    <a href="blog-single-sidebar.html"
                      ><img
                        class="thumb"
                        src="../assets/images/blog-grid3.jpg"
                        alt="#"
                    /></a>
                  </div>
                  <div class="content-body">
                    <div class="meta-data">
                      <ul>
                        <li>
                          <i class="lni lni-tag"></i>
                          <a href="javascript:void(0)">Education</a>
                        </li>
                        <li>
                          <i class="lni lni-calendar"></i>
                          <a href="javascript:void(0)">January 05, 2023</a>
                        </li>
                      </ul>
                    </div>
                    <h4 class="title">
                      <a href="blog-single-sidebar.html"
                        >15 days satisfaction seminar education</a
                      >
                    </h4>
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's.
                    </p>
                    <div class="button">
                      <a href="blog-single-sidebar.html" class="btn"
                        >Read More</a
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-12">
                <div
                  class="single-news custom-shadow-hover wow fadeInUp"
                  data-wow-delay=".4s"
                >
                  <div class="image">
                    <a href="blog-single-sidebar.html"
                      ><img
                        class="thumb"
                        src="../assets/images/blog-grid1.jpg"
                        alt="#"
                    /></a>
                  </div>
                  <div class="content-body">
                    <div class="meta-data">
                      <ul>
                        <li>
                          <i class="lni lni-tag"></i>
                          <a href="javascript:void(0)">Online Courses</a>
                        </li>
                        <li>
                          <i class="lni lni-calendar"></i>
                          <a href="javascript:void(0)">January 25, 2023</a>
                        </li>
                      </ul>
                    </div>
                    <h4 class="title">
                      <a href="blog-single-sidebar.html"
                        >How to Study Online Courses Effectively</a
                      >
                    </h4>
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's.
                    </p>
                    <div class="button">
                      <a href="blog-single-sidebar.html" class="btn"
                        >Read More</a
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-12">
                <div
                  class="single-news custom-shadow-hover wow fadeInUp"
                  data-wow-delay=".2s"
                >
                  <div class="image">
                    <a href="blog-single-sidebar.html"
                      ><img
                        class="thumb"
                        src="../assets/images/blog-grid2.jpg"
                        alt="#"
                    /></a>
                  </div>
                  <div class="content-body">
                    <div class="meta-data">
                      <ul>
                        <li>
                          <i class="lni lni-tag"></i>
                          <a href="javascript:void(0)">Language</a>
                        </li>
                        <li>
                          <i class="lni lni-calendar"></i>
                          <a href="javascript:void(0)">January 10, 2023</a>
                        </li>
                      </ul>
                    </div>
                    <h4 class="title">
                      <a href="blog-single-sidebar.html"
                        >How to Learn a Foreign Language in 3 Steps</a
                      >
                    </h4>
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's.
                    </p>
                    <div class="button">
                      <a href="blog-single-sidebar.html" class="btn"
                        >Read More</a
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-12">
                <div
                  class="single-news custom-shadow-hover wow fadeInUp"
                  data-wow-delay=".4s"
                >
                  <div class="image">
                    <a href="blog-single-sidebar.html"
                      ><img
                        class="thumb"
                        src="../assets/images/blog-grid3.jpg"
                        alt="#"
                    /></a>
                  </div>
                  <div class="content-body">
                    <div class="meta-data">
                      <ul>
                        <li>
                          <i class="lni lni-tag"></i>
                          <a href="javascript:void(0)">Education</a>
                        </li>
                        <li>
                          <i class="lni lni-calendar"></i>
                          <a href="javascript:void(0)">January 05, 2023</a>
                        </li>
                      </ul>
                    </div>
                    <h4 class="title">
                      <a href="blog-single-sidebar.html"
                        >15 days satisfaction seminar education</a
                      >
                    </h4>
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's.
                    </p>
                    <div class="button">
                      <a href="blog-single-sidebar.html" class="btn"
                        >Read More</a
                      >
                    </div>
                  </div>
                </div>
              </div> -->
            </div>

            <div class="pagination center">
              <ul class="pagination-list">
                <li v-if="totalPage > 1" ><a @click="goPrePage()">Prev</a></li>
                <li v-for="i, index in showPageBtn" :key="index" :class="{active: i == currentPage}"
                  @click="pageOffset(i)">
                  <a v-if="i" class="notPointer">{{i}}</a>
                  <a v-else>···</a>
                </li>
                <li v-if="totalPage > 1"><a @click="goNextPage()">Next</a></li>
              </ul>
            </div>
          </div>
          <aside class="col-lg-4 col-md-5 col-12">
            <div class="sidebar">
              <!-- <div class="widget search-widget">
                <h5 class="widget-title">Search Here</h5>
                <form action="#">
                  <input type="text" placeholder="Search Here..." />
                  <button type="submit">
                    <i class="lni lni-search-alt"></i>
                  </button>
                </form>
              </div> -->

              <div class="widget popular-feeds">
                <h5 class="widget-title">历届会议</h5>
                <div class="popular-feed-loop">

                  <div class="single-popular-feed" v-for="his,index in historyMeeting" :key="index"  @click="newsDetail(his.id)">
                    <div class="feed-img">
                      <a href="javascript:void(0)"
                        ><img :src=his.coverImage alt="#"
                      /></a>
                    </div>
                    <div class="feed-desc">
                      <h6 class="post-title">
                        <a href="javascript:void(0)">{{ his.title }}</a>
                      </h6>
                      <span class="time"
                        ><i class="lni lni-calendar"></i>{{ fmtime(his.createTime) }}</span
                      >
                    </div>
                  </div>
                  <!-- <div class="single-popular-feed">
                    <div class="feed-img">
                      <a href="blog-single.html"
                        ><img src="../assets/images/blog-sidebar2.jpg" alt="#"
                      /></a>
                    </div>
                    <div class="feed-desc">
                      <h6 class="post-title">
                        <a href="blog-single.html"
                          >How to Study Online Courses Effectively</a
                        >
                      </h6>
                      <span class="time"
                        ><i class="lni lni-calendar"></i> {{fmtime('2023-04-15 00:00:00')}}</span
                      >
                    </div>
                  </div>
                  <div class="single-popular-feed">
                    <div class="feed-img">
                      <a href="blog-single.html"
                        ><img src="../assets/images/blog-sidebar3.jpg" alt="#"
                      /></a>
                    </div>
                    <div class="feed-desc">
                      <h6 class="post-title">
                        <a href="blog-single.html"
                          >How To Find The Right Courses for you.</a
                        >
                      </h6>
                      <span class="time"
                        ><i class="lni lni-calendar"></i> 30th Jan 2023</span
                      >
                    </div>
                  </div> -->
                </div>
              </div>

              <div class="widget categories-widget">
                <h5 class="widget-title">分类</h5>
                <ul class="custom">
                  <li v-for="category,index in categoryList" :key="index"  >
                    <a @click="queryNewsByCategory(category.id)"><div :class="category.id == checkCategory?'checked':''">{{category.name}}</div> <span>{{category.sort}}</span></a>
                  </li>
                </ul>
              </div>

              <!-- <div class="widget popular-tag-widget">
                <h5 class="widget-title">Popular Tags</h5>
                <div class="tags">
                  <a href="javascript:void(0)">Online Courses</a>
                  <a href="javascript:void(0)">Design</a>
                  <a href="javascript:void(0)">UX</a>
                  <a href="javascript:void(0)">Study</a>
                  <a href="javascript:void(0)">Usability</a>
                  <a href="javascript:void(0)">Tech</a>
                  <a href="javascript:void(0)">Html</a>
                  <a href="javascript:void(0)">Develop</a>
                  <a href="javascript:void(0)">Bootstrap</a>
                  <a href="javascript:void(0)">Business</a>
                </div>
              </div> -->
            </div>
          </aside>
        </div>
      </div>
    </section>
    <a
      href="javascript:void(0)"
      class="scroll-top btn-hover"
      @click="scrollToAnchor('top')"
    >
      <i class="lni lni-chevron-up"></i>
    </a>
  </div>
</template>

<script>
import indexnav from "@/components/nav/indexnav.vue";
import banner from "@/components/header/banner.vue";
import {newsList,category,historyMeeting} from "@/api/api"
import { formatDates,formatDate } from "@/utils/index"


export default {
  name: "IndexPage",
  components: {
    indexnav,
    banner,
  },
  data() {
    return {
      bannerUrl: ["BANNER.png"],
      totalPage: 0,
      currentPage:1,
      categoryId: 0,
      checkCategory: -1,
      newsList: [],
      categoryList: [],
      historyMeeting:[]
      

    };
  },
  created(){
    this.getCategoryList();
    this.getNewsList();
    this.getHistoryMeeting();
  },
  computed:{
    showPageBtn () {
      const pageNum = this.totalPage
      const index = this.currentPage
      if (pageNum <= 5) return [...new Array(pageNum)].map((v, i) => i + 1)
      if (index <= 2) return [1, 2, 3, 0, pageNum]
      if (index >= pageNum - 1) return [1, 0, pageNum - 2, pageNum - 1, pageNum]
      if (index === 3) return [1, 2, 3, 4, 0, pageNum]
      if (index === pageNum - 2) return [1, 0, pageNum - 3, pageNum - 2, pageNum - 1, pageNum]
      return [1, 0, index - 1, index, index + 1, 0, pageNum]
    }
  },
  mounted() {
    //  TouchSlide({
    // 		slideCell:"#news_slide",
    // 		mainCell:".bd ul",
    // 		effect:"leftLoop",
    // 		autoPlay:true//自动播放
    //  });
    const slide = document.querySelector(".hero-slider");
    console.log(slide);
    tns({
      container: ".hero-slider",
      items: 1,
      slideBy: "page",
      autoplay: false,
      mouseDrag: true,
      gutter: 0,
      nav: true,
      controls: false,
      controlsText: [
        '<i class="lni lni-arrow-left"></i>',
        '<i class="lni lni-arrow-right"></i>',
      ],
    });


    document.addEventListener("click", (evnt) => {
      let anchor = evnt.target.closest('a[href^="#"]');
      console.log(anchor)
      if (anchor) {
        evnt.preventDefault();
        let value = anchor.getAttribute("set_data");
        if (value) {
          this.newsDetail(value);
        }
      }
    });
  },
  methods: {
    scrollToAnchor(id) {
      var anchor = document.getElementById(id);
      anchor.scrollIntoView();
    },
    goToIndex(){
       this.$router.push({
          path:"/index"
        })
    },
    newsDetail(newsId) {
      this.$router.push({
        path: "/aboutHisNews/" + newsId,
      });
    },
    caseDetail(type) {
      this.$router.push({
        path: "/caseDetail/" + type,
      });
    },
     pageOffset (i) {
      if (i === 0 || i === this.currentPage){
          return
      }
      this.currentPage = i
      this.getNewsList();

    },
     goPrePage () {
      if (this.currentPage == 1){
          return
      }
      this.currentPage = this.currentPage-1
      this.getNewsList();
    },
    goNextPage () {
      if(this.currentPage == this.totalPage){
        return
      }
      this.currentPage = this.currentPage+1
      this.getNewsList();
    },
    getNewsList(){
      let that = this;
        let query ={
          pageNum: this.currentPage,
        }
        if(this.categoryId > 0){
          query.categoryId = this.categoryId;
        }
         newsList(query).then(response => {
          if(response.code == 0){
             this.totalPage = response.pageSizeCount;
             this.newsList = response.rows
          }
          that.loading = false;
      });
    },
    getCategoryList(){
      let that = this;
        category().then(response => {
          if(response.code == 0){
             that.categoryList = response.data
          }
          that.loading = false;
      });
    },
    getHistoryMeeting(){
      let that = this;
        historyMeeting().then(response => {
          if(response.code == 0){
             that.historyMeeting = response.data
          }
          that.loading = false;
      });
    },
    fmtime(val) {
      return formatDates(new Date(val),'yyyy-MM-dd');
    },
    queryNewsByCategory(categoryId){
      this.categoryId = categoryId;
      this.checkCategory =categoryId;
      this.getNewsList();
    },
  },
};
</script>

<style>
.latest-news-area .single-news .content-body p {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    word-break: break-all;
    -webkit-box-orient: vertical;
}
.categories-widget .custom .checked {
  color: #8fc31f;
}
</style>
